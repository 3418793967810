// @ts-nocheck
import React, { useContext } from "react";
import logo from "../asset/image/htawbicon.png";
import paymentcoll from "../asset/image/paymentcoll.png";
import { Link } from "react-router-dom";
import { MdArrowRight } from "react-icons/md";
import { LoginContext } from "../store/context/LoginProvider";
const MainFooter = ({ socialLink }) => {
  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);

  return (
    <div className="bg-black bg-opacity-50 row border border-white border-0">
      <div className="col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-around">
        <img style={{ width: "8rem" }} src={logo} alt="logo" />
        <div className="text-white fst-italic text-muted text-center">
          2023 Htaw B <br /> All rights reserved
        </div>
      </div>
      <div className="col-md-6 col-lg-3 row d-none d-md-flex boder border-1 border-start border-secondary border-opacity-50 align-items-center">
        {category &&
          category.length &&
          category.map((cat, idx) => (
            <Link
              key={idx}
              className="text-warning col-md-6 opacity-75 text-decoration-none fs-6 fw-bold"
              to={`${cat.name}`}
            >
              {cat.name} <MdArrowRight size={30} />
            </Link>
          ))}
      </div>
      <div className="col-md-6 col-lg-3 row d-none d-md-flex boder border-1 border-start border-secondary border-opacity-50 align-items-center ms-2 ps-5">
        <div className="d-flex flex-column gap-4 ps-1">
          {/* FB  */}
          <a
            href={socialLink?.facebook}
            target="_blank"
            className="text-decoration-none pe-3 text-primary"
          >
            <i className="fa-brands fs-2 fa-facebook"></i>
            <span className="fs-5 ms-3 text-secondary">Facebook</span>
          </a>

          {/* Messenger */}
          <a
            href={socialLink?.messenger}
            target="_blank"
            className="text-decoration-none pe-3 text-warning"
          >
            <i className="fa-brands fs-2 fa-facebook-messenger"></i>
            <span className="fs-5 ms-3 text-secondary">Messenger</span>
          </a>

          {/* Viber */}

          <a
            href={socialLink?.viber}
            target="_blank"
            className="text-decoration-none pe-3 text-danger"
          >
            <i className="fa-brands fs-2 fa-viber"></i>
            <span className="fs-5 ms-3 text-secondary">Viber</span>
          </a>
          {/* Instagram */}

          <a
            href={socialLink?.instagram}
            target="_blank"
            className="text-decoration-none pe-3 text-info"
          >
            <i className="fa-brands fs-2 fa-telegram"></i>
            <span className="fs-5 ms-3 text-secondary">Telegram</span>
          </a>
        </div>
      </div>
      <div className="col-md-6 col-lg-3 row d-none d-md-flex boder border-1 border-start border-secondary border-opacity-50 align-items-center justify-content-center">
        <img
          style={{ objectFit: "contain", width: "100%", height: "18rem" }}
          classname="mx-auto"
          src={paymentcoll}
          alt=".."
        />
      </div>
    </div>
  );
};

export default MainFooter;
