// @ts-nocheck
import React, { useCallback, useContext, useState } from "react";
// lib
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import HeaderIcon from "../../asset/image/auland.png";
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import icon from "../../../src/asset/image/htawbicon.png";
import { BaseURL } from "../../api/apiUrl";
// utils
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
//icons
import { UserCircleIcon } from "@heroicons/react/24/solid";
import pointIcon from "../../asset/image/point-image.png";
// context
import { LoginContext } from "../../store/context/LoginProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { getProfileApi } from "../../api/profileApi";
import {
  claimPocket,
  claimPromotion,
  isPromoMaintain,
} from "../../api/promotionApi";
import { toastError, toastSuccess } from "../../utils/toast";
import { useConfettie } from "../../store/zustand";
const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
  loading,
}) => {
  let { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = pathname.substring(1, pathname.length).split("/")[0];
  const navigate = useNavigate();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [openLottery, setOpenLottery] = useState(false);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [point, setPoint] = useState(userData?.point || 0);
  const [p_balance, setPBalance] = useState(userData?.p_balance || 0);
  const [ploading, setPloading] = useState(false);
  const [promoMaintain, setPromoMaintain] = useState("1");

  const { onConOpen, setShowVD, setPocketAmount } = useConfettie();

  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) {
              setAmount(res?.data?.balance);
              setPoint(res?.data?.point);
              setPBalance(res?.data?.p_balance);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // The tab is hidden
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);
    setPBalance(userData?.p_balance || 0);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    userData?.balance,
    userData?.token,
    userData?.point,
    userData?.p_balance,
  ]);

  useEffect(() => {
    isPromoMaintain().then((res) => setPromoMaintain(res.is_maintain));
  }, []);

  const handlePromoClaim = useCallback(async () => {
    if (promoMaintain === "1") return;

    setPloading(true);
    try {
      await claimPromotion({ token: userData?.token })
        .then((res) => toastSuccess(res.message))
        .finally(() => setPloading(false));
    } catch (error) {
      const err = await error.json();
      toastError(err.message);
      setPloading(false);
    }
  }, [promoMaintain, userData?.token]);

  const handleGiftBox = useCallback(
    (value) => {
      if (!onConOpen || !setShowVD) return;

      setPocketAmount(value);
      onConOpen();
      setShowVD();
    },
    [onConOpen, setShowVD, setPocketAmount]
  );

  const handlePocketClaim = async () => {
    if (ploading) return;
    setPloading(true);

    await claimPocket({ token: userData?.token })
      .then(async (res) => {
        if (res.status === "success") {
          setPloading(false);

          //fetch user's balance again
          await getProfileApi({ token: userData?.token })
            .then((res) => {
              if (res?.status === 200) {
                setAmount(res?.data?.balance);
                setPoint(res?.data?.point);
                setPBalance(res?.data?.p_balance);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => setPloading(false));

          handleGiftBox(res.amount);
        }
      })
      .catch(async (err) => {
        setPloading(false);
        const error = await err.json();
        toastError(error.message);
      });
  };

  return (
    <nav>
      <div className="d-none position-relative d-lg-flex justify-content-end justify-content-md-between align-items-start bg-black">
        {/* Lang Switch */}
        <Link
          to="/"
          className="d-flex rounded-0 text-decoration-none text-white align-items-center"
        >
          <img src={icon} style={{ width: "7rem" }} alt="icon" />
        </Link>

        <section className="w-100">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-end pe-3"
              style={{ paddingLeft: "10px" }}
            >
              <div
                onClick={() => setOpenLang(!openLang)}
                className="d-flex cursor-pointer align-items-center px-2"
              >
                {language === EN ? (
                  <FlagImage width={35} flagImg={UKFlag} />
                ) : language === MM ? (
                  <FlagImage width={35} flagImg={MMFlag} />
                ) : language === CN ? (
                  <FlagImage width={35} flagImg={ChinaFlag} />
                ) : (
                  <FlagImage width={35} flagImg={ThaiFlag} />
                )}
                {/* Expand Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
              </div>
              {/* Announcement Icon */}
              <img width={30} height={30} src={HeaderIcon} alt="..." />
            </div>
            {/* Land Switch: DropDown  */}
            <div
              className={`position-absolute ${
                openLang ? "d-flex" : "d-none"
              } flex-column text-black gap-1 bg-white rounded-3 p-2`}
              style={{ left: "7rem", top: 40, zIndex: 9999 }}
            >
              <div
                className={`${
                  language !== MM ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(MM)}
              >
                <FlagImage width={25} flagImg={MMFlag} />
                <span>ဗမာစာ</span>
              </div>
              <div
                className={`${
                  language !== EN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(EN)}
              >
                <FlagImage width={25} flagImg={UKFlag} />
                <span>English</span>
              </div>
              <div
                className={`${
                  language !== THAI ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(THAI)}
              >
                <FlagImage width={25} flagImg={ThaiFlag} />
                <span>ไทย</span>
              </div>
              <div
                className={`${
                  language !== CN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(CN)}
              >
                <FlagImage width={25} flagImg={ChinaFlag} />
                <span>中国人</span>
              </div>
            </div>

            {/** marquee */}
            <div className="" style={{ flex: 1 }}>
              <HeaderMarquee marquee={marquee} />
            </div>

            <div className="flex">
              {userData?.token ? (
                <div className="d-flex flex-row-reverse justify-content-between align-items-center gap-3">
                  <div className="d-flex bg-warning rounded-2 flex-row ps-1 me-1 justify-content-between align-items-center">
                    <div className="dropdown " style={{ cursor: "pointer" }}>
                      <UserCircleIcon
                        width={35}
                        style={{ color: "darkred" }}
                        data-bs-toggle="dropdown"
                      />
                      <ul
                        style={{
                          zIndex: "10000",
                          fontSize: "1.3rem",
                          fontFamily: "cursive",
                        }}
                        className="dropdown-menu bg-dark rounded-3"
                      >
                        <Link to="/profile" className="text-decoration-none ">
                          <li
                            className="profileBtn text-warning ps-3 py-1"
                            style={{ color: "purple" }}
                          >
                            {t("profile")}
                          </li>
                        </Link>

                        <Link to="/cashin" className="text-decoration-none">
                          <li
                            className="profileBtn text-warning ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("cashIn")}
                          </li>
                        </Link>

                        <Link to="/cashout" className="text-decoration-none">
                          <li
                            className="profileBtn text-warning ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("cashOut")}
                          </li>
                        </Link>

                        <Link to="/betslip" className="text-decoration-none">
                          <li
                            className="profileBtn text-warning ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("betslip")}
                          </li>
                        </Link>

                        <Link
                          to=""
                          onClick={handleLogout}
                          className="text-decoration-none"
                        >
                          <li
                            className="profileBtn text-warning ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("logout")}
                          </li>
                        </Link>
                      </ul>
                    </div>

                    <div className="me-1 text-center d-flex flex-wrap justify-content-center">
                      {/* Main Balance */}
                      <span className="d-flex align-items-start fw-bolder fs-6 fw-bold pe-md-2 text-black">
                        {Intl.NumberFormat("en-us").format(parseInt(amount))}
                        <span
                          style={{ fontSize: "0.7rem" }}
                          className="ms-2 fst-italic"
                        >
                          MMK
                        </span>
                      </span>
                    </div>
                  </div>

                  {/** Point */}
                  <Link to="/point" className="point_div">
                    <img
                      src={pointIcon}
                      style={{ width: "2.3rem" }}
                      alt="point"
                      className="point_image"
                    />
                    <small className="text-nowrap py-1 point_boder">
                      Point - {Math.floor(point)}
                    </small>
                  </Link>

                  {promoMaintain === "0" && (
                    <>
                      <button
                        disabled={ploading}
                        onClick={handlePromoClaim}
                        className={`${
                          ploading && "btn-secondary"
                        } btn btn-outline-warning fw-bold btn-sm col-md-3 col-2xl-4 max-w-100`}
                      >
                        Promotion - {Math.floor(p_balance)}
                      </button>
                      <button
                        disabled={ploading}
                        onClick={handlePocketClaim}
                        className="btn btn-info btn-sm col-md-3 col-2xl-4 max-w-100 fw-bold"
                      >
                        Pocket Money
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <div className="w-100 d-flex gap-3 ps-3">
                  <Link
                    to="/login"
                    style={{ width: "10rem" }}
                    className="text-decoration-none d-flex align-items-center justify-content-end"
                  >
                    <button className="rounded-5 btn btn-outline-warning border-2 px-3 w-100 me-2">
                      <span className="fw-bolder">{t("login")}</span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          {/**  Category Bar  */}
          <div className="d-none pt-2 d-md-flex text-center overflow-auto justify-content-center align-items-center mt-0">
            {/** <div className="text-warning d-flex align-items-center">
              <div
                role="button"
                onClick={() => {
                  setOpenLottery(!openLottery);
                }}
                style={{ backgroundColor: "purple" }}
                className="d-flex roundedEnd align-items-center justify-content-center py-1 shadow categoryHover cursor-pointer "
              >
                <LazyLoadImage
                  style={{ width: "30px", objectFit: "contain" }}
                  effect="blur"
                  src={LotteryIcon}
                />
                <div className="fs-6 ms-2 fw-bolder">
                  {t("sectionTwoD.lottery")}
                </div>
              </div>
            </div> */}
            <div className="text-warning gap-0 d-inline-flex rounded">
              {category && !loading ? (
                category.length ? (
                  category.map((cat, idx) => (
                    <div key={idx} className="catNav">
                      <div
                        key={idx}
                        onClick={() => {
                          navigate(`${cat.name}`);
                          setOpenLottery(false);
                        }}
                        style={{
                          backgroundColor: "#471712",
                          paddingTop: ".1rem",
                          paddingBottom: ".1rem",
                        }}
                        className={`d-flex roundedEnd border border-2 border-danger border-top-0 border-end-0 border-start-0 align-items-center categoryHover justify-content-center py-1 shadow ${
                          pathname.split("%20").join("") ===
                          cat.name.split(" ").join("")
                            ? "bg-warning text-black"
                            : "shadow"
                        } ${idx === category.length - 1 && ""} cursor-pointer`}
                      >
                        <LazyLoadImage
                          style={{
                            width: "30px",
                            objectFit: "contain",
                            height: "30px",
                          }}
                          effect="blur"
                          src={`${BaseURL}/${cat.image}`}
                        />
                        <div className="fs-6 ms-2 fw-bolder">{cat.name}</div>
                      </div>
                      <div className="catDropdown py-2 rounded-3 position-absolute">
                        {cat.category_game_providers &&
                        cat.category_game_providers.length ? (
                          cat.category_game_providers.map((a) => (
                            <Link
                              className="text-decoration-none"
                              to={`${cat.name}/${a.id}`}
                            >
                              <div
                                style={{ fontSize: ".8rem" }}
                                className="py-2 text-warning text-uppercase fw-bolder cattext text-start"
                              >
                                <img
                                  style={{ width: "20px" }}
                                  src="https://cdn-icons-png.flaticon.com/512/4906/4906292.png"
                                  className="mx-2"
                                  alt="flaticon"
                                />
                                {a.name}
                              </div>
                            </Link>
                          ))
                        ) : (
                          <div>Empty</div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>no category</div>
                )
              ) : (
                Array.from({ length: 5 }).map((a, idx) => (
                  <div
                    key={idx}
                    style={{ minWidth: "10rem" }}
                    className="d-flex roundedEnd gap-2 align-items-center px-3 py-2 shadow categoryHover cursor-pointer bg-secondary bg-opacity-75 "
                  >
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="bg-dark pulse bg-opacity-50 rounded-circle p-2"
                    ></div>
                    <div
                      style={{ width: "80%", height: "15px" }}
                      className="bg-dark pulse bg-opacity-50 rounded-3 p-2"
                    ></div>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </div>

      {/** lottery dropdown */}
      <div
        className={`bg-dark rounded-3 ${
          !openLottery && "d-none"
        } h-auto position-absolute`}
        style={{
          left: "20.5%",
          transform: "translate(-50%,0%)",
          zIndex: "999999",
          width: "10rem",
        }}
      >
        <ul
          className="mt-1 fw-bolder font-monospace list-unstyled"
          style={{ fontSize: ".96rem" }}
        >
          <Link
            to="/one/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("oneD")}
            </li>
          </Link>

          <Link
            to="/two/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("twoD")}
            </li>
          </Link>

          <Link
            to="/crypto/one/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              Crypto 1D
            </li>
          </Link>

          <Link
            to="/crypto/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              Crypto 2D
            </li>
          </Link>

          <Link
            to="/three/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("threeD")}
            </li>
          </Link>

          <Link
            to="/win-number"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("winNoHistory")}
            </li>
          </Link>
          <Link
            to="/winner-list"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("winnerListHistory.title")}
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  );
};

export default WebsiteHeader;
