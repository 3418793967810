import React from "react";
import { useState } from "react";
import icon from "../../asset/image/icon.png";

const LiveChat = () => {
  const [open, setIsOpen] = useState(false);
  return (
    <main>
      <div onClick={() => setIsOpen(!open)} className="livechat football">
        <img className="p-2" style={{ width: "5rem" }} src={icon} alt="livec" />
      </div>
      <div className={`${open ? "d-block" : "d-none"} lciframe`}>
        <iframe
          src="https://htawb2d3d.com/livechat.html"
          title="Live Chat Widget"
          className="livechatiframe"
        />
      </div>
    </main>
  );
};

export default LiveChat;
