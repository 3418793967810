// @ts-nocheck
import React, { Fragment, useContext, useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useParams } from "react-router-dom";
import { Gameplay, GetGames } from "../../api/gameManagement";
import { LoginContext } from "../../store/context/LoginProvider";
import { RandColor } from "../../components/Game/RandColor";
import { Empty } from "../../components/Game/Empty";
import Loading from "../../components/shared_comp/Loading";
import Iframe from "../../components/Game/Iframe";
import { useTranslation } from "react-i18next";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

import ICON from "../../asset/image/logo.png";
const ProviderGame = () => {
  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);

  const [{ userData }] = useContext(LoginContext);

  // const [user_code,setUser_code] =useState(userData.user_code)
  const { cat } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [games, setGames] = useState([]);
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  const handleClick = (p_code, cate_code, g_code) => {
    // console.log(p_code, cate_code, g_code);
    if (userData) {
      setIframeLoading(true);
      Gameplay(userData.user_code, p_code, cate_code, g_code)
        .then((data) => {
          let url = data.Url;
          window.open(url, "_blank");
          setIframeLoading(false);
          // console.log(data.Url);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
      alert("Need to login first !");
      window.location.replace("/login");
    }
  };

  const FilterGames = () => {
    if (search) {
      return games.filter((game) =>
        game.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return games;
  };

  useEffect(() => {
    setLoading(true);
    const providerID = location.pathname.split("/").pop();
    const categoryID = category
      ?.filter((cate) => cate.name.toLowerCase().includes(cat.toLowerCase()))
      .map((a) => a.id);

    GetGames(...categoryID, providerID).then((data) => {
      data && setGames(data);
      setLoading(false);
    });
    setColor(RandColor());
  }, [cat, category, location.pathname]);

  if (!loading && !games.length) {
    return <Empty />;
  }
  return (
    <>
      {iframeLoading ? (
        <Loading loading={true} full={true} />
      ) : (
        <div className="col-12 col-md-11 mx-auto mt-3 mb-3 mt-md-0">
          <div className="d-flex justify-content-center align-items-center me-md-4">
            <div className="col-10 col-md-3 input_search_game rounded-1 overflow-hidden">
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="searchgame text-white col-10 py-2 ms-3 border-0 fs-6 bg-transparent"
                placeholder="Search Game ..."
              />
              <MagnifyingGlassIcon
                className="text-warning
                "
                style={{ width: "1.6rem" }}
              />
            </div>
          </div>
          <div className="w-100 mt-4 d-flex justify-content-center align-items-center flex-wrap gap-1 gap-md-5 mx-auto pb-5 mb-5">
            {!loading ? (
              FilterGames().length ? (
                FilterGames().map((game, idx) => (
                  <div
                    className={`mb-4 mb-md-2 provider_games position-relative`}
                    key={idx}
                  >
                    <div className="">
                      <LazyLoadImage
                        style={{
                          width: "10.5rem",
                          height: "9rem",
                          objectFit: "cover",
                        }}
                        src={game.image}
                        placeholderSrc={ICON}
                        className="cursor-pointer provider_games_clipath"
                      />
                    </div>
                    <div className="d-flex flex-row-reverse mt-2 align-items-center justify-content-center">
                      <div
                        className="fw-bolder text-center mt-1"
                        style={{ color: "#FDDF7D" }}
                      >
                        <span className=""> {game.name.substring(0, 20)}</span>
                        <small>{game.name.length >= 20 && "..."}</small>
                      </div>
                    </div>

                    <div className="w-100 h-100 provider_games_playBtn d-flex justify-content-center align-items-center">
                      <button
                        onClick={() =>
                          handleClick(game.p_code, game.cate_code, game.g_code)
                        }
                        className="btn btn-outline-warning rounded-0"
                      >
                        Play Game
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="font-monospace text-warning fs-4 mt-5 pt-5 text-center">
                  {t("noresult")}
                </div>
              )
            ) : (
              <Fragment>
                {Array.from({ length: 21 }).map((_, idx) => (
                  <div key={idx}>
                    <div
                      className={`${color} pulse provider_games bg-opacity-10 rounded-3`}
                      style={{
                        width: "11.5rem",
                        height: "10rem",
                      }}
                    ></div>
                    <div
                      className={`pulse ${color} mt-2 rounded-5 bg-opacity-10`}
                      style={{ height: "1.4rem" }}
                    ></div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      )}
      {showIframe && (
        <Iframe
          setIframeUrl={setIframeUrl}
          setShowIframe={setShowIframe}
          iframeUrl={iframeUrl}
        />
      )}
    </>
  );
};

export default ProviderGame;
